// vars
$theme-colors: (
"primary": #2a3037,
"secondary": #f8c51c,
"gray2": #283037,
"gray3": #dddddd,
"gray4": #eeeeee,
"gray6": #f4f4f4
);

$line-height: 2;

// shared
@font-face {
  font-family: Gentium;
  src: url(../font/GentiumPlus-R.woff);
}
@font-face {
  font-family: Gentium;
  font-style: italic;
  src: url(../font/GentiumPlus-I.woff);
}

$font-family-serif: Gentium;
$font-family-base: $font-family-serif;

@import "./bootstrap/bootstrap";

body {
  background-color: $gray-100;
  overflow: hidden;
}

h3 {
  text-transform: uppercase;
  color: $gray-500;
  font-size: $font-size-base * 0.8;
}

h1 {
  font-size: $font-size-base * 1.5;
  font-weight: $font-weight-bold;
}

span {
  line-height: 2;
}

.sidebar-left {
  background-color: theme-color("primary");
  min-height: 0 !important;
}


#note-content {
  padding-bottom: 101px !important;
}

@include media-breakpoint-up(lg) {
  .sidebar-left {
    height: 100% !important;
  }
  #btn-home {
    width: 100% !important;
  }
}



.sidebar-right {
  background-color: $gray-200;
}

.sans-serif {
  font-family: $font-family-sans-serif !important;
}

.btn-custom {
  color: white !important;
  text-transform: uppercase !important;
  font-family: $font-family-sans-serif;
}
// notes page

.placeholder {
  color: $gray-500 !important;
}


#note-title {
  outline: none;
}

#transcript-wrapper {
  height: 100% !important;
  overflow: scroll;
  padding-top: 1rem !important;
}

#transcript-wrapper::-webkit-scrollbar { 
  display: none;
}

#annotations {
  display: block;
}

#annotations-wrapper {
  overflow: scroll;
  height: 100%;
}

#annotations-wrapper::-webkit-scrollbar { 
  display: none;
}

.annotation-item {
  position: absolute;
  left: 0;
  height: 60px;
  border-top: 1px solid white;
  color: #616871;
  outline: none;
}

#white-overlay {
  background: linear-gradient($gray-100, #FFFFFF00);
  position: absolute;
  left: 0;
  pointer-events: none;
}

#home-heading {
  border-bottom: 1px solid $gray-400;
}

#home-content {
  overflow: auto;
}
#not-final {
  color: #ccc !important;
}

// highlight
span {
  background-color: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.highlight {
  background-color: theme-color("secondary");
}

.interim {
  color: $gray-500;
}

